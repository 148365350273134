import { useState, useEffect, Suspense } from "react";
import {
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { DocumentType, ModuleLayout, StripeSubscriptionStatus } from "./Enums";
import { useAuth } from "./hooks/useAuth";
import { Pages } from "./pages";
import { Header } from "./components/Header/Header";
import { HeaderPublic } from "./components/Header/HeaderPublic";
// import { Footer } from "./components/Footer/Footer";
import { Loading } from "./components/UI/Loading";
import { useQueryClient } from "react-query";
import { getById } from "./axios/documents";
import { useSelector } from "react-redux";
import { getUserId } from "./store/authSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: `${theme.palette.grey[100]} !important`,
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    "& ::-webkit-scrollbar": {
      width: 4,
      height: 0,
      backgroundColor: "transparent",
    },
  },
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    display: "flex",
    flex: 1,
  },
}));

type RenderType = {
  page: any;
};
const Render = (props: RenderType) => {
  const { page } = props;
  const classes = useStyles();
  const params = useParams();
  const { isAuthenticated, subscriptionOverdue, user } = useAuth();
  const { pathname } = useLocation();
  // const { isAuthenticated, isSuperAdmin, monitorRefreshToken } = useAuth();
  // const [headerData, setHeaderData] = useState({ title: page.title || "" });
  const Component = page.component;

  // useTimeout(() => monitorRefreshToken, 1000);

  // if (page.isAdmin && !isSuperAdmin) {
  //   return <Redirect to="/" />;
  // }

  if (page.secure && !isAuthenticated) {
    return <Navigate to={`/login?r=${pathname}`} />;
  }

  let document;
  if (page.document) {
    document = { ...page.document, id: params.id };
  }

  if (page.layout === ModuleLayout.WITH_HEADER) {
    return (
      <>
        <Header report={page.report} document={document} />
        <div
          id="mainContainer"
          className={classes.container}
          style={{ overflowY: page.scroll ? "auto" : "hidden" }}
        >
          <Component />
          {/* {page.scroll && <Footer />} */}
        </div>
      </>
    );
  }

  if (page.layout === ModuleLayout.WITH_PUBLIC_HEADER) {
    return (
      <>
        <HeaderPublic />
        <div
          id="mainContainer"
          className={classes.container}
          style={{ overflowY: page.scroll ? "auto" : "hidden" }}
        >
          <Component />
        </div>
      </>
    );
  }

  // if (module.layout === ModuleLayout.WITH_HOME_HEADER) {
  //   return (
  //     <>
  //       <HomeHeader />
  //       <div className={classes.container}>
  //         <Component {...props} setHeaderData={setHeaderData} />
  //       </div>
  //     </>
  //   );
  // }

  if (page.layout === ModuleLayout.WITHOUT_HEADER) {
    return <Component />;
  }
  return <Component />;
};

export const Router = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Suspense fallback={<Loading />}>
        <Routes>
          {Pages.map((page) => {
            return (
              <Route
                path={page.path}
                element={<Render page={page} />}
                key={page.path}
              />
            );
          })}
        </Routes>
        {/* <Route component={Home} /> */}
      </Suspense>
    </div>
  );
};
