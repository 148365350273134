import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks/useResponsive";
import { ObjectType } from "../../Types";
import { Button } from "../FormFields/Buttons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 120,
    // padding: "0 16px",
    // width: 300,
    margin: "auto",
  },
  logo: ({ isDesktop }: ObjectType) => ({
    // textTransform: "uppercase",
    fontSize: isDesktop ? "5rem" : "3rem",
    fontFamily: "Maven Pro",
    color: "#859624", //theme.palette.secondary.main,
    // textShadow: "0 0 3px rgba(133,150,36,0.5)",
  }),
  columnRight: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
  },
  slogan2: {
    color: "#333",
    fontSize: "1.6rem",
    textShadow: "0 0 3px rgba(87,73,133,0.6)",
  },
}));

type PageProps = {};
export const HeaderPublic = ({}: PageProps) => {
  const { isDesktop } = useResponsive();
  const classes = useStyles({ isDesktop });
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <div className={classes.logo}>dibs</div>
      {/* <div className={classes.columnRight}>
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.05)",
            padding: "8px 16px",
            borderRadius: 8,
            marginTop: 16,
          }}
        >
          <img
            src="/images/google-play-store.png"
            style={{ width: 35, height: 35, marginRight: 16, marginTop: 2 }}
          />
          <img
            src="/images/apple-app-store.png"
            style={{ width: 35, height: 35 }}
          />
        </div>
      </div> */}
    </div>
  );
};

export default HeaderPublic;
