import { lazy } from "react";
import { ModuleLayout } from "../Enums";

// const AccountSetup = lazy(() => import("./AccountSetup"));
// const AccountOverdue = lazy(() => import("./AccountOverdue"));
// const AddressBook = lazy(() => import("../reports/AddressBook"));
// const AssociatedCompanies = lazy(
//   () => import("../reports/AssociatedCompanies")
// );
// const Company = lazy(() => import("./Company"));
// const Connections = lazy(() => import("../reports/Connections"));
// const Discussions = lazy(() => import("../reports/Discussions"));
const Home = lazy(() => import("./Home"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));

// const LandingPage = lazy(() => import("./LandingPage"));
const Login = lazy(() => import("./Login"));
// const Properties = lazy(() => import("../reports/Properties"));
// const PurchaseOrders = lazy(() => import("../reports/PurchaseOrders"));
// const Property = lazy(() => import("./Property"));
// const Roles = lazy(() => import("../reports/Roles"));
// const ShipToAddresses = lazy(() => import("../reports/ShipToAddresses"));
const SignUp = lazy(() => import("./SignUp"));
// const Vendors = lazy(() => import("../reports/Vendors"));

export const Pages = [
  {
    path: "/",
    component: Home,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "Dibs",
    },
    exact: true,
    scroll: true,
  },
  {
    path: "/r/:referralId",
    component: Home,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "Dibs",
    },
    exact: true,
    scroll: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "Dibs",
    },
    exact: true,
    scroll: true,
  },
  // {
  //   path: "/home",
  //   component: Home,
  //   layout: ModuleLayout.WITHOUT_HEADER,
  //   report: {
  //     name: "Dibs",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/account-setup",
  //   component: AccountSetup,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Account Setup",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/account-overdue",
  //   component: AccountOverdue,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Account Overdue",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/address-book",
  //   component: AddressBook,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Address Book",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/companies",
  //   component: AssociatedCompanies,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Companies",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/company",
  //   component: Company,
  //   layout: ModuleLayout.WITH_HEADER,
  //   document: {
  //     name: "Company",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/connections",
  //   component: Connections,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Connections",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  {
    path: "/login",
    component: Login,
    layout: ModuleLayout.WITH_PUBLIC_HEADER,
    exact: true,
  },
  // {
  //   path: "/messages",
  //   component: Discussions,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Messages",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/properties",
  //   component: Properties,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Properties",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/property/:id",
  //   component: Property,
  //   layout: ModuleLayout.WITH_HEADER,
  //   document: {
  //     name: "Property",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/roles",
  //   component: Roles,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Roles",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: "/ship-to-addresses",
  //   component: ShipToAddresses,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Ship To Addresses",
  //   },
  //   exact: true,
  //   secure: true,
  // },
  {
    path: "/sign-up",
    component: SignUp,
    layout: ModuleLayout.WITH_PUBLIC_HEADER,
    exact: true,
  },
  // {
  //   path: "/vendors",
  //   component: Vendors,
  //   layout: ModuleLayout.WITH_HEADER,
  //   report: {
  //     name: "Vendors",
  //   },
  //   exact: true,
  //   secure: true,
  // },
];
