import { makeStyles } from "@mui/styles";
import { ObjectType } from "../../../Types";

import { Button } from "../../FormFields/Buttons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: 12,
  },
  title: ({ direction }: ObjectType) => ({
    flex: 1,
    paddingLeft: direction === "right" ? 16 : 0,
    fontSize: "1.2rem",
  }),
}));

type SidebarHeaderType = {
  title?: string;
  direction: "left" | "right";
  onClose: () => void;
  className?: string;
};
export const SidebarHeader = ({
  title,
  direction,
  onClose,
  className,
}: SidebarHeaderType) => {
  const classes = useStyles({ direction });
  return (
    <div className={`${classes.root} ${className}`}>
      {direction === "left" && (
        <>
          <div className={classes.title}>{title}</div>
          <div>
            <Button icon="chevron left" onClick={onClose} />
          </div>
        </>
      )}
      {direction === "right" && (
        <>
          <div>
            <Button icon="chevron right" onClick={onClose} />
          </div>
          <div className={classes.title}>{title}</div>
        </>
      )}
    </div>
  );
};
