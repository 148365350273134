import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

export const useResponsive = () => {
  const isDesktop = useMediaQuery({
    minWidth: 1080,
  });

  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 1079,
  });

  const isMobile = useMediaQuery({
    maxWidth: 767,
  });

  const screenSize = useMemo(() => {
    if (isTablet) {
      return "tablet";
    } else if (isMobile) {
      return "mobile";
    }
    return "desktop";
  }, [isMobile, isTablet]);

  return {
    isDesktop,
    isTablet,
    isMobile,
    screenSize,
  };
};
