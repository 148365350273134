import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ObjectType } from "../../Types";
import { MainMenu } from "./MainMenu";
import { FloatingLabel } from "../UI/FloatingLabel";
import { useQuery } from "react-query";
import { CommandType, DocumentType, QueryType } from "../../Enums";
import { command, getFiltered, insert } from "../../axios/documents";
import { getCompanyId } from "../../store/authSlice";
import { Button } from "../FormFields/Buttons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 64,
    padding: "0 16px",
    // marginBottom: theme.spacing(0.5),
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.light,
  },
  menu: {
    height: "unset !important",
    cursor: "pointer",
  },
  logo: {
    marginLeft: theme.spacing(2),
    fontSize: "1.2rem",
    fontFamily: "Varela Round",
    textTransform: "uppercase",
  },
  backButton: {
    minHeight: "32px !important",
    padding: "0 12px 0 8px",
    marginLeft: theme.spacing(2),
  },
  columnRight: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
  },
}));

type PageProps = {
  report?: ObjectType;
  document?: ObjectType;
};
export const Header = ({ report, document }: PageProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useSelector(getCompanyId);
  const [mainMenuIsVisible, setMainMenuIsVisible] = useState(false);
  const { data } = useQuery(CommandType.HEADER_DATA, () =>
    command(CommandType.HEADER_DATA, {})
  );
  return (
    <>
      <div className={classes.root}>
        <Icon
          name="bars"
          className={classes.menu}
          onClick={() => setMainMenuIsVisible(true)}
        />
        <div className={classes.logo}>
          {report && report.name}
          {document && `${document.name}`}
        </div>
        <div className={classes.columnRight}>
          {companyId > 0 && (
            <>
              <FloatingLabel label="" onClick={() => navigate("/alerts")}>
                <Icon name="alarm" size="large" />
              </FloatingLabel>
              <FloatingLabel
                label={data?.data.unreadMessages || ""}
                onClick={() => navigate("/messages")}
              >
                <Icon name="chat" size="large" />
              </FloatingLabel>
              <FloatingLabel label="" onClick={() => navigate("/address-book")}>
                <Icon name="address book outline" size="large" />
              </FloatingLabel>
              <FloatingLabel label="4" onClick={() => navigate("/files")}>
                <Icon name="folder open outline" size="large" />
              </FloatingLabel>
            </>
          )}
        </div>
      </div>
      <MainMenu
        visible={mainMenuIsVisible}
        onClose={() => setMainMenuIsVisible(false)}
      />
    </>
  );
};

export default Header;
