import axios from "axios";
import { CommandType, DocumentType, QueryType } from "../Enums";
import { ObjectType } from "../Types";

let cancelTokenSource: ObjectType = {};

export const getById = async (
  documentType: DocumentType,
  id?: string | number
) => {
  if (id) {
    const { data } = await axios.get(`/document/${documentType}/${id}`);
    return data;
  }
};

export const getFiltered = async (params: ObjectType) => {
  const key = JSON.stringify(params);
  // let pageParam = params.pageParam || 0;
  // const data = [];
  // for (let i = pageParam; i < pageParam + 5; i++) {
  //   data.push({ id: i, name: `test - ${i}` });
  // }
  // return { data, nextCursor: pageParam < 100 ? pageParam + 5 : undefined, totalRows: 1000 };
  if (cancelTokenSource[key]) {
    cancelTokenSource[key].cancel("canceled request");
  }
  cancelTokenSource[key] = axios.CancelToken.source();
  const { data } = await axios.post(`/document/filtered`, params, {
    cancelToken: cancelTokenSource[key].token,
  });
  return data;
};

// export const getDropdownFiltered = async (entity, params) => {
//   const { data } = await axios.post(`/entities/${entity}/filtered/dropdown`, params);
//   return data;
// };

export const getFilterField = async (params: ObjectType) => {
  const { data } = await axios.post(`/document/filter-field`, params);
  return data;
};

export const getByIds = async (ids: string[]) => {
  const { data } = await axios.post(`/document/ids`, ids);
  return data;
};

export const update = async (documentType: DocumentType, data: ObjectType) =>
  await axios.patch(`document`, { documentType, ...data });

export const updateMany = async (
  documentType: DocumentType,
  data: ObjectType
) => await axios.post(`/document/many`, { documentType, ...data });

export const command = async (
  commandType: CommandType,
  data: ObjectType,
  config?: ObjectType
) => await axios.post(`/command`, { commandType, ...data }, config);

export const insert = async (
  documentType: DocumentType,
  data: ObjectType,
  config?: ObjectType
) => await axios.post(`document`, { documentType, ...data }, config);

export const insertMany = async (data: ObjectType) =>
  await axios.post(`document/many`, data);

export const insertImage = async (data: ObjectType, config?: ObjectType) =>
  await axios.post(`image`, data, config);

export const remove = async (documentType: DocumentType, data: ObjectType) =>
  await axios.delete(`/document`, { data: { documentType, ...data } });

export const referral = async (data: ObjectType) =>
  await axios.post(`/referral`, data);

// export const getPDF = async (params: ObjectType, fileName?: string) => {
//   const { data } = await axios.post(`document`, {
//     ...params,
//     queryType: QueryType.PDF,
//   });
//   const link = document.createElement("a");
//   link.href = data.url;
//   // link.download = fileName;
//   link.target = "_blank";
//   link.click();
// };
