import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { createTheme, Theme } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

import LogRocket from "logrocket";

import { Router } from "./Router";
import { Axios } from "./axios/axios";
import { store, persistor } from "./store/store";
// import Stripe from "./Stripe";

if (process.env.REACT_APP_ENV === "production") {
  console.log("init logrocket");
  LogRocket.init("snani5/dibs");
}

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

ReactGA.initialize("G-6WM16Y4KYF");

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
      dark: "#5b4d34", //"#5e7744", //"#447744",
      light: "#eee",
    },
    secondary: {
      main: "#859624",
      dark: "#296699",
      light: "#333",
    },
    success: {
      main: "#b7e060",
      light: "#cde0a6",
      dark: "#73a82d",
    },
    warning: {
      main: "#E0D7A6",
      light: "#e0d6a6",
      dark: "#E0D7A6",
    },
    action: {
      active: "#4183c4",
      hover: "#ddd",
    },
  },
});

export const App = () => {
  return (
    <ReduxProvider store={store}>
      <Toaster position="top-right" />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
              <Axios>
                <Router />
              </Axios>
            </PersistGate>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};
