import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 52,
    width: 68,
    borderRadius: 4,
    // padding: theme.spacing(2),
    // margin: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  label: {
    position: "absolute",
    top: 6,
    right: 8,
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: "1px 6px",
    borderRadius: 12,
  },
}));

type PageProps = {
  label: string;
  onClick?: () => void;
  children: any;
};
export const FloatingLabel = ({ label, onClick, children }: PageProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      {label && <div className={classes.label}>{label}</div>}
      <div>{children}</div>
    </div>
  );
};

export default FloatingLabel;
