import React from "react";
import { makeStyles } from "@mui/styles";
import { Icon, SemanticICONS, Loader } from "semantic-ui-react";
import classnames from "classnames";

import { ObjectType } from "../../Types";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: ({ fluid, border }: ObjectType) => ({
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "transparent",
    border: border
      ? `1px solid ${theme.palette.grey[400]}`
      : "0px solid transparent",
    width: fluid ? "100%" : "",
    minHeight: 40,
    whiteSpace: "nowrap",
    padding: "4px 12px",
    color: theme.palette?.primary.main,
    borderRadius: 4,
    fontSize: 15,
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    // fontWeight: "bold",
    "&:hover": {
      color: `#111 !important`,
      // border: "1px solid #777",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
  }),
  title: {
    paddingRight: ({ direction }: ObjectType) =>
      direction === "right" ? "10px !important" : "",
    paddingLeft: ({ direction }: ObjectType) =>
      direction === "left" ? "10px !important" : "",
    width: "unset !important",
    flex: 1,
  },
  removeButton: {
    width: 30,
    height: 26,
    minHeight: 26,
  },
  linkButton: {
    border: "unset",
    padding: 0,
    minHeight: 1,
    color: theme.palette?.action.active,
    "&:hover": {
      border: "unset",
      backgroundColor: "unset",
      color: `${theme.palette?.action.hover} !important`,
    },
  },
  backButton: {
    minHeight: "32px !important",
    padding: "0 12px 0 8px",
    marginLeft: theme.spacing(2),
  },
}));

type ButtonType = {
  icon?: SemanticICONS;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  loading?: boolean;
  children?: any;
  className?: string;
  iconClassName?: string;
  iconStyle?: ObjectType;
  fluid?: boolean;
  style?: ObjectType;
  direction?: "left" | "right";
  border?: boolean;
};
export const Button = ({
  icon,
  onClick,
  type = "button",
  disabled,
  loading,
  className = "",
  iconClassName,
  iconStyle,
  fluid,
  style,
  direction = "left",
  border,
  children,
}: ButtonType) => {
  const classes = useStyles({ fluid, direction, border });
  return (
    <button
      className={`${classes.root} ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={style}
    >
      <>
        {direction === "left" && (
          <>
            {loading && (
              <div style={{ position: "relative", paddingRight: 15 }}>
                <Loader active size="mini" />
              </div>
            )}
            {icon && !loading && (
              <Icon
                name={icon}
                className={iconClassName}
                style={{
                  margin: 0,
                  height: "unset",
                  fontSize: "1.2rem",
                  width: "1rem",
                  ...iconStyle,
                }}
              />
            )}
            {children && icon && (
              <div className={classnames({ [classes.title]: !!icon })}>
                {children}
              </div>
            )}
          </>
        )}
        {direction === "right" && (
          <>
            {children && icon && (
              <div className={classnames({ [classes.title]: !!icon })}>
                {children}
              </div>
            )}
            {loading && (
              <div style={{ position: "relative", paddingLeft: 15 }}>
                <Loader active size="mini" />
              </div>
            )}
            {icon && !loading && (
              <Icon
                name={icon}
                className={iconClassName}
                style={{ margin: 0, height: "unset", ...iconStyle }}
              />
            )}
          </>
        )}
        {children && !icon && !loading && children}
      </>
    </button>
  );
};

type RemoveButtonType = {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};
export const RemoveButton = ({
  onClick,
  disabled,
  className,
}: RemoveButtonType) => {
  const classes = useStyles({});
  return (
    <Button
      icon="delete"
      onClick={onClick}
      disabled={disabled}
      className={classes.removeButton}
    />
  );
};

type LinkButtonType = {
  onClick: () => void;
  disabled?: boolean;
  style?: ObjectType;
  loading?: boolean;
  icon?: SemanticICONS;
  children: React.ElementType | string;
};
export const LinkButton = ({
  onClick,
  disabled,
  style,
  children,
  loading,
  icon,
}: LinkButtonType) => {
  const classes = useStyles({});
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={classes.linkButton}
      style={style}
      loading={loading}
      icon={icon}
    >
      {children}
    </Button>
  );
};

export const BackButton = () => {
  const classes = useStyles({});
  const navigate = useNavigate();
  return (
    <Button
      icon="caret left"
      className={classes.backButton}
      border
      onClick={() => navigate(-1)}
    >
      Back
    </Button>
  );
};
