import { useDispatch, batch, useSelector } from "react-redux";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { getById } from "../axios/documents";
import {
  AuthStatusType,
  DocumentType,
  QueryType,
  StripeSubscriptionStatus,
} from "../Enums";
import {
  setEmail,
  setJwt,
  setExpiration,
  setRefreshToken,
  setUserId,
  setUser,
  getJwt,
  setIdToken,
  getRefreshToken,
  getExpiration,
  getEmail,
  setCompanyId,
  setCompany,
  getCompany,
  getUser,
} from "../store/authSlice";
import { ObjectType } from "../Types";
import { useCallback, useMemo } from "react";
import { Utility } from "../Utility";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL || "",
  ClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT || "",
};

const errorCodes: { [key: string]: string } = {
  UsernameExistsException: "A user with this email already exists.",
  InvalidParameterException: "Your password needs to be longer than 6 digits.",
  UserNotConfirmedException: "Your email has not been confirmed.",
  UserNotFoundException: "User cannot be found.",
  NotAuthorizedException: "Incorrect credentials.",
  InvalidPasswordException:
    "Your password must be 8 characters or longer, must contain lowercase and uppercase characters.",
  LimitExceededException:
    "Too many password reset attempts, please try again later.",
};

const userPool = new CognitoUserPool(poolData);

export const useAuth = () => {
  const dispatch = useDispatch();
  const jwt = useSelector(getJwt);
  const refreshToken = useSelector(getRefreshToken);
  const expiration = useSelector(getExpiration);
  const email = useSelector(getEmail);
  const company = useSelector(getCompany);
  const user = useSelector(getUser);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const login = ({ email, password }: ObjectType) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          batch(() => {
            dispatch(setEmail(email));
            dispatch(setJwt(result.getAccessToken().getJwtToken()));
            dispatch(setExpiration(result.getAccessToken().getExpiration()));
            dispatch(setIdToken(result.getIdToken().getJwtToken()));
            dispatch(setRefreshToken(result.getRefreshToken().getToken()));
          });

          const userId = result.getIdToken().payload.sub;
          const user = await getById(DocumentType.USER, userId);
          batch(() => {
            dispatch(setUser(user));
            dispatch(setCompanyId({ companyId: user.companyId }));
          });

          resolve({ type: AuthStatusType.SUCCESS });
        },
        onFailure: (err) => {
          resolve({
            type: AuthStatusType.ERROR,
            message: errorCodes[err.name],
          });
        },
      });
    }) as ObjectType;
  };

  const signUp = ({ email, password }: ObjectType) => {
    return new Promise((resolve) => {
      userPool.signUp(email, password, [], [], async (err, result) => {
        if (err) {
          resolve({
            type: AuthStatusType.ERROR,
            message: errorCodes[err.name],
          });
        } else {
          resolve({
            type: AuthStatusType.SUCCESS,
          });
        }
      });
    }) as ObjectType;
  };

  const logout = async () => {
    const cognitoUser = new CognitoUser({
      Username: email as string,
      Pool: userPool,
    });
    await cognitoUser.signOut();
    dispatch(setJwt(null));
    dispatch(setExpiration(null));
    dispatch(setRefreshToken(null));
  };

  const monitorRefreshToken = useCallback(() => {
    if (
      refreshToken &&
      jwt &&
      email &&
      expiration &&
      expiration - 120 < Utility.now()
    ) {
      console.log("expiration: ", expiration);
      navigate("/login");
    }

    // return new Promise((resolve) => {
    //   if (
    //     refreshToken &&
    //     jwt &&
    //     email &&
    //     expiration &&
    //     expiration - 120 < Utility.now()
    //   ) {
    //     console.log("expiration: ", expiration);
    //     const cognitoUser = new CognitoUser({
    //       Username: email,
    //       Pool: userPool,
    //     });
    //     const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    //     cognitoUser.refreshSession(token, (err, result) => {
    //       if (err) {
    //         console.error(err);
    //         return;
    //       }
    //       batch(() => {
    //         dispatch(setJwt(result.getAccessToken().getJwtToken()));
    //         dispatch(setExpiration(result.getAccessToken().getExpiration()));
    //         dispatch(setRefreshToken(result.getRefreshToken().getToken()));
    //       });
    //     });
    //     setTimeout(() => resolve(true), 500);
    //   } else {
    //     resolve(true);
    //   }
    // });
  }, [email, expiration, jwt, navigate, refreshToken]);

  const isAuthenticated = useMemo(() => {
    return !!jwt && !!expiration && expiration > Utility.now();
  }, [expiration, jwt]);

  const subscriptionOverdue = useMemo(() => {
    return [
      StripeSubscriptionStatus.CANCELED,
      StripeSubscriptionStatus.PAST_DUE,
      StripeSubscriptionStatus.UNPAID,
    ].includes(company?.stripeSubscriptionStatus);
  }, [company?.stripeSubscriptionStatus]);

  return {
    login,
    signUp,
    logout,
    monitorRefreshToken,
    isAuthenticated,
    subscriptionOverdue,
    company,
    user,
  };
};
