import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../UI/Sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { UserProfile } from "../UI/UserProfile";

const useStyles = makeStyles((theme) => ({
  root: {},
  menu: { padding: theme.spacing(1) },
  categoryContainer: {
    padding: theme.spacing(2),
  },
  category: { fontSize: "1.4rem", paddingBottom: theme.spacing(1) },
  item: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
    fontSize: "1.2rem",
  },
  option: {
    padding: "16px 32px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

const newMenu = [
  { name: "Home", path: "/home" },
  { name: "Products", path: "/products" },
  { name: "Ship To Addresses", path: "/ship-to-addresses" },
  { name: "Purchase Orders", path: "/purchase-orders" },
  // { name: "Vendors", path: "/vendors" },
];

type MainMenuType = {
  visible: boolean;
  onClose: () => void;
};
export const MainMenu = ({ visible, onClose }: MainMenuType) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();

  return (
    <>
      <Sidebar
        visible={visible}
        onClose={onClose}
        direction="left"
        title={"DIBS"}
        style={{ width: 400 }}
      >
        <UserProfile onClose={onClose} />
        {isAuthenticated && (
          <>
            <div className={classes.menu}>
              {newMenu.map((item) => (
                <div
                  className={classes.item}
                  onClick={() => {
                    navigate(item.path);
                    onClose();
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {/* <div
            className={classes.option}
            onClick={() => {
              navigate("/login");
              onClose();
            }}
          >
            Login
          </div>
          <div
            className={classes.option}
            onClick={() => {
              navigate("/sign-up");
              onClose();
            }}
          >
            Sign Up
          </div>
          <div
            className={classes.option}
            onClick={() => {
              navigate("/help/getting-started");
              onClose();
            }}
          >
            Help
          </div> */}
          </>
        )}
        <div style={{ flex: 1 }}></div>

        {isAuthenticated && (
          <div className={classes.menu}>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className={classes.item}
                style={{ flex: 1 }}
                onClick={async () => {
                  navigate("/companies");
                  onClose();
                }}
              >
                Company
              </div>
              <Button style={{ marginLeft: 8 }}>Create</Button>
            </div> */}

            {/* <div
              className={classes.item}
              onClick={async () => {
                navigate("/companies");
                onClose();
              }}
            >
              Create Company
            </div> */}
            <div
              className={classes.item}
              onClick={async () => {
                await logout();
                navigate("/login");
                onClose();
              }}
            >
              Logout
            </div>
          </div>
        )}
      </Sidebar>
    </>
  );
};
