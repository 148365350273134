import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../store/authSlice";
import { LinkButton } from "../FormFields/Buttons";
// import { EditUser } from "../User/EditUser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 32,
    paddingRight: theme.spacing(2),
  },
  image: {
    width: 65,
    height: 65,
    minWidth: 65,
    minHeight: 65,
    borderRadius: 50,
    border: "1px solid #ccc",
    marginLeft: 16,
    marginRight: 16,
  },
  contact: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "1.2rem",
  },
}));

type PageProps = {
  onClose: () => void;
};
export const UserProfile = ({ onClose }: PageProps) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const [editUserVisible, setEditUserVisible] = useState(false);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.image}></div>
        <div className={classes.contact}>
          <div>
            <div className={classes.name}>
              {user?.firstName} {user?.lastName}
            </div>
            <div>{user?.email}</div>
          </div>

          <LinkButton
            onClick={() => {
              setEditUserVisible(true);
            }}
            style={{ fontSize: "1rem", minHeight: 30 }}
          >
            Edit
          </LinkButton>
        </div>
      </div>
      {/* <EditUser
        visible={editUserVisible}
        onClose={() => setEditUserVisible(false)}
      /> */}
    </>
  );
};

export default UserProfile;
