export enum DocumentType {
  EMPTY = "",
  PROPERTY = "property",
  PROPERTY_SCHEDULE = "property_schedule",
  REFERRAL = "referral",
  USER = "user",
  USER_PROPERTY = "user_property",
}

export enum QueryType {
  USER_PROPERTIES = "user_properties",
}

export enum EntityType {}
// COMPANY = "COMPANY",
// COMPANY_USER = "COMPANY_USER",
// COMPANY_SHIP_METHOD = "COMPANY_SHIP_METHOD",
// CONNECTION = "CONNECTION",
// CONTACT = "CONTACT",
// PURCHASE_ORDER_STATUS = "PURCHASE_ORDER_STATUS",
// ROLE = "ROLE",
// SHIP_METHOD = "SHIP_METHOD",
// SHIP_TO_ADDRESS = "SHIP_TO_ADDRESS",
// VENDOR_CONNECTED = "VENDOR_CONNECTED",
// VENDOR = "VENDOR",
// VISIBILITY = "VISIBILITY",

export enum CommandType {
  HEADER_DATA = "HEADER_DATA",
  REFERRAL = "referral",
}
// ADD_MEMBER = "ADD_MEMBER",
// CREATE_SETUP_INTENT = "CREATE_SETUP_INTENT",
// DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
// REMOVE_MEMBER = "REMOVE_MEMBER",

export enum DateTimeFormat {
  DEFAULT = "D ttt",
}

export enum ModuleLayout {
  WITH_HEADER = "WITH_HEADER",
  WITHOUT_HEADER = "WITHOUT_HEADER",
  WITH_PUBLIC_HEADER = "WITH_PUBLIC_HEADER",
}

export enum AuthStatusType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum ReportRowType {
  GROUP = "g",
  ITEM = "i",
}

// export enum SentType {
//   DRAFT = "DRAFT",
//   SENT = "SENT",
// }

export enum VisibilityType {
  VISIBLE = "VISIBLE",
  NOT_VISIBLE = "NOT_VISIBLE",
}

export enum StripeSubscriptionStatus {
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}
