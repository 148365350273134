import { makeStyles } from "@mui/styles";
import { Sidebar as SemanticSidebar } from "semantic-ui-react";
import { ObjectType } from "../../../Types";
import { SidebarHeader } from "./SidebarHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette?.grey[200],
    boxShadow: "0 0 20px rgba(100,100,100,0.9) !important",
  },
}));

type PageProps = {
  visible: boolean;
  onClose: () => void;
  title?: string;
  direction?: "left" | "right";
  style?: ObjectType;
  children: any;
};
export const Sidebar = ({
  visible,
  onClose,
  title,
  direction = "right",
  style,
  children,
}: PageProps) => {
  const classes = useStyles();
  return (
    <SemanticSidebar
      visible={visible}
      className={classes.root}
      onHide={onClose}
      animation="overlay"
      direction={direction}
      style={style}
    >
      <SidebarHeader title={title} direction={direction} onClose={onClose} />
      {children}
    </SemanticSidebar>
  );
};

export default Sidebar;
